import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { shuffleArray, sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/animations/scroll.scss"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import {
  FixedFacewallHomepage,
  StaticHero,
  StaticHeading,
  Sizzle,
  ImageText,
  FirstTime,
  InTheCommunity,
  ReferringProvider,
  LearnMoreCards,
  Exparel,
  ASIRD
} from "./index/index"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import TestimonialPreview from "../components/Cards/TestimonialPreview"
import TestimonialGrid from "../components/Cards/TestimonialGrid"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      didScroll: false,
      scrollPosition: "relative",
      scrollOverflow: "unset",
      playing: false,
      extTheater: false,
      toggleCarouselsClass: false,
      language: "en"
    }
    this.toggleCarousel = this.toggleCarousel.bind(this)
    // this.window = window;

    // this.overrideTheater = this.overrideTheater.bind(this);
  }

  toggleCarousel() {
    var that = this
    that.setState({ toggleCarouselsClass: true })
    window.removeEventListener(
      "aos:in:home-section",
      this.toggleCarousel,
      false
    )
    window.removeEventListener(
      "aos:in:featured-carousel",
      this.toggleCarousel,
      false
    )
    window.removeEventListener("scroll", this.toggleCarousel, false)
  }

  componentDidMount() {
    //document.body.style.height = "102vh";
    //document.body.style.overflow = "scroll !important";
    window.addEventListener("aos:in:home-section", this.toggleCarousel)
    window.addEventListener("aos:in:featured-carousel", this.toggleCarousel)
    window.addEventListener("scroll", this.toggleCarousel)

    if (this.props.pageContext && this.props.pageContext.language) {
      this.setState({
        language: this.props.pageContext.language
      })
    }
  }

  render() {
    var pageContext = this.props.pageContext
    var post
    var language

    if (pageContext && pageContext.language) {
      language = this.state.language
      switch (language) {
        case "en":
          post = this.props.data.allUniquePagesJson.nodes[0]
          break
        case "es":
          post = this.props.data.spanishIndex.nodes[0]

          break
        default:
          post = this.props.data.allUniquePagesJson.nodes[0]
      }
    } else {
      post = this.props.data.allUniquePagesJson.nodes[0]
    }

    var staticStyle = {
      position: this.state.scrollPosition,
      overflow: this.state.scrollOverflow,
      width: "100%",
      zIndex: 1
    }
    var sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    let allReviews = this.props.data.allReviews.nodes
    if (this.state.language === "es")
      allReviews = this.props.data.allReviewsEs.nodes
    let sortedReviews = sortReviewsByDate(allReviews)

    //shuffleArray(allReviews);
    let fixedFacewallReviews = sortedReviews.slice(0, 8)

    return (
      <SharedStateProvider>
        <Layout
          className={`main-homepage ${
            this.state.language === "es" ? "es" : null
          }`}
          language={language}
          layoutClass={"homepage-fab"}
          noFab={true}
          pageTitle={"main-homepage"}
          toastText={null}
        >
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={this.props.location.pathname}
          />
          {/* {language !== "es" && <BackToBusiness tel="(619) 287-5000" telStripped="+16192875000" />} */}

          {/* <StaticCurtain
            playing={this.state.playing}
            sizzleButtonStyle={sizzleButtonStyle}
            post={post}
            language={language}
          /> */}

          <div className="static-container" style={staticStyle}>
            {post.hasSizzle ? (
              <Sizzle
                playing={this.state.playing}
                sizzleButtonStyle={sizzleButtonStyle}
                post={post}
                language={language}
              >
                <StaticHeading
                  playing={this.state.playing}
                  sizzleButtonStyle={sizzleButtonStyle}
                  post={post}
                  language={language}
                />
              </Sizzle>
            ) : (
              <StaticHero post={post} />
            )}

            {language == "en" && (
              <>
                {post.featuredReviews.length > 1 && (
                  <div className="columns">
                    {post.featuredReviews.map(card => (
                      <div key={card.youtube} className="column">
                        <TestimonialPreview
                          language={language}
                          youtube={card.youtube}
                          image={card.image}
                          heading={card.heading}
                          subheading={card.subHeading}
                        />
                      </div>
                    ))}
                  </div>
                )}

                <TestimonialGrid language={language} reviews={allReviews} />
              </>
            )}

            <ImageText language={language} post={post} textImage noReverse />

            {/* <ImageTexts language={language} imageTopMobile imageTexts={post.imageTexts} /> */}

            {/* <HomeCarousel language={language} toggleCarouselsClass={this.state.toggleCarouselsClass} /> */}
            {/* <FixedFacewallHomepage
              language={language}
              heading={
                language === "es"
                  ? "Escuche Sobre Nuestros Pacientes"
                  : "Hear From Our Patients And Referring Doctors"
              }
              reviews={fixedFacewallReviews}
            /> */}

            {/* <HeadingVideo post={post} /> */}
            <Exparel post={post.homeExparel} />

            {/*<JournalFeature />*/}

            <LearnMoreCards language={language} post={post.learnMoreCards} />

            {post.inTheCommunity && post.inTheCommunity.hasThisSection && (
              <InTheCommunity language={language} data={post.inTheCommunity} />
            )}

            <ReferringProvider
              language={language}
              data={post.referringProvider}
            />

            <ASIRD post={post.homeAsird} />

            <FirstTime
              colorBack={false}
              language={language}
              post={post}
              noReverse
            />

            {/* <RatingsAndReviews
                post={post}
                google={google}
                facebook={facebook}
                language={language}
                topDown
                iconless
                east={arbor}
                coastal={plymouth}
              /> */}

            <AllRatingsReviews
              colorBack={true}
              animation
              heading={post.homeRatingsReviews.heading}
              language={language}
              buttonHref={post.homeRatingsReviews.buttonOne.href}
              buttonText={post.homeRatingsReviews.buttonOne.buttonText}
            />
          </div>
        </Layout>
      </SharedStateProvider>
    )
  }
}

IndexPage.propTypes = {
  pageContext: PropTypes.object
}

export const pageQuery = graphql`
  query reviewsIndexQuery {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { ne: "ESP" } }
      limit: 30
    ) {
      nodes {
        ...FacewallData
      }
    }
    allReviewsEs: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ESP" } }
    ) {
      nodes {
        ...FacewallData
      }
    }
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        hasSizzle
        mobileHeading
        intro
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
        }
        featuredReviews {
          heading
          subHeading
          youtube
          image
        }
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
              youtube
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          heading
          text
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        seoHeading
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        svgSubheading
      }
    }
    spanishIndex: allSpanishUniquePagesJson(filter: { title: { eq: "/es/" } }) {
      nodes {
        mobileHeading
        intro
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
          heading
          imageId
          text
        }
        homeRatingsReviews {
          heading
          text
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        seoHeading
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        firstTimePatient {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        svgSubheading
        svgHeadingMobile
      }
    }
  }
`

export default IndexPage
